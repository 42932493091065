/*!
 * BootstrapVue Icons Custom CSS (https://bootstrap-vue.org)
 */
.bv-no-focus-ring:focus {
  outline: none;
}

@media (max-width: 575.98px) {
  .bv-d-xs-down-none {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .bv-d-sm-down-none {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .bv-d-md-down-none {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .bv-d-lg-down-none {
    display: none !important;
  }
}
.bv-d-xl-down-none {
  display: none !important;
}

.b-icon.bi {
  display: inline-block;
  overflow: visible;
  vertical-align: -0.15em;
}
.b-icon.b-icon-animation-cylon, .b-icon.b-iconstack .b-icon-animation-cylon > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon, .b-icon.b-iconstack .b-icon-animation-cylon > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-cylon-vertical, .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon-vertical;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon-vertical, .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-fade, .b-icon.b-iconstack .b-icon-animation-fade > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-fade;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-fade, .b-icon.b-iconstack .b-icon-animation-fade > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
  transform-origin: center;
  animation: 2s infinite linear normal b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-reverse, .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
  transform-origin: center;
  animation: 2s infinite linear reverse b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse, .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-pulse, .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
  transform-origin: center;
  animation: 1s infinite steps(8) normal b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-pulse, .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-reverse-pulse, .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
  transform-origin: center;
  animation: 1s infinite steps(8) reverse b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse-pulse, .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-throb, .b-icon.b-iconstack .b-icon-animation-throb > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-throb;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-throb, .b-icon.b-iconstack .b-icon-animation-throb > g {
    animation: none;
  }
}

@keyframes b-icon-animation-cylon {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
@keyframes b-icon-animation-cylon-vertical {
  0% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(-25%);
  }
}
@keyframes b-icon-animation-fade {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes b-icon-animation-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes b-icon-animation-throb {
  0% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.btn .b-icon.bi,
.nav-link .b-icon.bi,
.dropdown-toggle .b-icon.bi,
.dropdown-item .b-icon.bi,
.input-group-text .b-icon.bi {
  font-size: 125%;
  vertical-align: text-bottom;
}

/*# sourceMappingURL=data:application/json;charset=utf-8,%7B%22version%22:3,%22sourceRoot%22:%22%22,%22sources%22:%5B%22../scripts/icons.scss%22,%22../src/_utilities.scss%22,%22../node_modules/bootstrap/scss/mixins/_breakpoints.scss%22,%22../src/icons/_icons.scss%22,%22../src/_variables.scss%22%5D,%22names%22:%5B%5D,%22mappings%22:%22AAAA;AAAA;AAAA;ACSE;EACE;;;AC+DA;EDzDE;IACE;;;ACwDJ;EDzDE;IACE;;;ACwDJ;EDzDE;IACE;;;ACwDJ;EDzDE;IACE;;;AADF;EACE;;;AEfN;EACE;EACA;EAGA,gBCwDqB;;ADrDvB;EAEE;EACA;;AAGA;EANF;IAOI;;;AAIJ;EAEE;EACA;;AAGA;EANF;IAOI;;;AAIJ;EAEE;EACA;;AAEA;EALF;IAMI;;;AAIJ;EAEE;EACA;;AAEA;EALF;IAMI;;;AAIJ;EAEE;EACA;;AAEA;EALF;IAMI;;;AAIJ;EAEE;EACA;;AAEA;EALF;IAMI;;;AAIJ;EAEE;EACA;;AAGA;EANF;IAOI;;;AAIJ;EAEE;EACA;;AAGA;EANF;IAOI;;;;AAMN;EACE;IACE;;EAEF;IACE;;;AAIJ;EACE;IACE;;EAEF;IACE;;;AAIJ;EACE;IACE;;EAEF;IACE;;;AAIJ;EACE;IACE;;EAEF;IACE;;;AAIJ;EACE;IACE;IACA;;EAEF;IACE;IACA;;;AAWA;AAAA;AAAA;AAAA;AAAA;EACE;EACA%22,%22file%22:%22bootstrap-vue-icons.css%22%7D */
